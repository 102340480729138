/** @jsx jsx */
import { useEffect } from 'react';
import { jsx } from 'theme-ui';
import Layout from '~/components/Layout';
import Metadata from '~/components/Metadata';

const AugustAdvocates = () => {
  useEffect(() => {
    if (typeof window !== 'undefined')
      window.location = 'https://itsaugust.typeform.com/to/PLjRfB3l';
  }, []);
  return (
    <Layout>
      <Metadata />
      August Advocates
    </Layout>
  );
};

export default AugustAdvocates;
